import { Theme, Box, SxProps, useTheme } from "@mui/material";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import React, { useCallback, useMemo, useState } from "react";
import { Typography } from "../Typography";
import { useDropzone, DropEvent, FileRejection, ErrorCode } from 'react-dropzone'
import { LinkButtonHref } from "../LinkButtonHref";
import _uniqWith from 'lodash/uniqWith'
import { useAlerts } from "saga-library/src/providers/Alerts";
import { BaseFileWithProgress } from "../../util/azureStorage";
import { Button } from "../Button";
const maxFileSize : number = 512000 // 500KB

export interface ImageUploadConfiguration {
  sx?: SxProps<Theme>
  files: BaseFileWithProgress[]
  setFiles: (files: BaseFileWithProgress[]) => void
  uploading: boolean
  dataTestId?: string
  dragAndDropEndText?: string
}

const areFilesEqual = (file1: File, file2: File) => {
  return file1.name === file2.name && file1.size === file2.size && file1.lastModified === file2.lastModified
}

function fileSizeValidator(file) {
  if (file.size > maxFileSize) {
    return {
      code: ErrorCode.FileTooLarge,
      message: `File is larger than 500KB`
    };
  }
  return null
}

export const ImageUpload = ({
  sx={},
  files,
  setFiles,
  uploading=false,
  dataTestId,
  dragAndDropEndText
}: ImageUploadConfiguration) => {
  const { showErrorAlert } = useAlerts()
  const theme = useTheme()
  const [ imgPath, setImgPath ] = useState<string>("")

  const fileCountValidator = useCallback((file) => {
    if (files.length > 1) {
      return {
        code: ErrorCode.TooManyFiles,
        message: 'Too many files'
      }
    }
    return null
  }, [files])

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent) => {
    if (uploading) return

    rejectedFiles.forEach((rejectedFile) => {
      //TODO: the convention for showing multiple error messages is in claim entry
      showErrorAlert(`File ${rejectedFile.file.name} was rejected. ${rejectedFile.errors[0].message}`)
    })

    let uniqueFiles : File[] = _uniqWith([...files, ...acceptedFiles], areFilesEqual)
    setFiles(uniqueFiles)
    setImgPath(URL.createObjectURL(uniqueFiles[0]))
  }, [files, setFiles, showErrorAlert, uploading])

  const {getRootProps, getInputProps, isDragActive} = useDropzone(
    {
      onDrop,
      accept: {
        'image/png': [],
        'image/jpeg': []
      },
      validator: (file) => fileCountValidator(file) ?? fileSizeValidator(file),
      multiple: false,
      maxFiles: 1,
      disabled: files.length > 0
    }
  )

  const baseStyle: React.CSSProperties = useMemo(() => {
    return {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '8px',
      borderWidth: '3px',
      borderColor: theme.palette.greys.light,
      borderRadius: '8px',
      borderStyle: 'dashed',
      outline: 'none',
      transition: 'border .24s ease-in-out',
      justifyContent: 'center',
      cursor: 'pointer',
      height: '142px'
    }
  }, [theme.palette.greys.light]);

  const activeDragStyle = useMemo(() => {
    return {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.greys.extraLight,
    }
  }, [theme.palette.greys.extraLight, theme.palette.primary.main]);

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeDragStyle : {}),
  }), [activeDragStyle, baseStyle, isDragActive]);

  const fileText = `file`

  const displayArea = useCallback(() => {
    if (files.length > 0) {
      return <Box sx={{width:"100%", height:"100%", position:'relative'}}>
        <img key={imgPath} src={imgPath} alt={'uploaded signature'} style={{width:"100%", height:"100%", objectFit: "contain"}}/>
        <Button
          variant="outlined"
          sx={{ position: 'absolute', top: 0, left: 0 }}
          onClick={() => setFiles([])}
          name={'clearButton'}
          dataTestId={'clear-signature-button'}
          size={'small'}
        >
          CLEAR
        </Button>
      </Box>
    }
    if (isDragActive) {
      return <Typography variant={'h6'} sx={{color: 'greys.dark'}}>{`Drop ${fileText} here...`}</Typography>
    }
    return <>
      <RowBox>
        <FileUploadOutlinedIcon sx={{color: 'greys.medium', fontSize: '4rem'}} />
      </RowBox>
      <RowBox>
        <Typography variant={'h6'} sx={{color: 'greys.dark'}}>{`Drag and drop ${fileText} here or`}</Typography>
        <Typography variant={'h6'} sx={{color: 'greys.dark'}}>&nbsp;</Typography>
        <Typography variant={'h6'} sx={{color: 'greys.dark'}}><LinkButtonHref text={`Select ${fileText}`} onClick={() => {}}/></Typography>
        {dragAndDropEndText ?
          <>
            <Typography variant={'h6'} sx={{color: 'greys.dark'}}>&nbsp;</Typography>
            <Typography variant={'h6'} sx={{color: 'greys.dark'}}>{`${dragAndDropEndText}.`}</Typography>
          </>
          :
          <Typography variant={'h6'} sx={{color: 'greys.dark'}}>.</Typography>}
      </RowBox>
    </>
  }, [files, isDragActive, imgPath])

  return (
    <>
      <Box data-testid={`${dataTestId}-drop-area`} sx={{...sx}} {...getRootProps({style})}>
        <><input {...getInputProps()} /></>
        {displayArea()}
      </Box>
      <RowBox
        sx={{
          justifyContent: 'space-between',
          py: 1,
          ...sx
        }}
      >
        <RowBox>
          <Typography variant={'body1'} sx={{color: 'greys.dark'}}>Supported formats: </Typography>
          <Typography sx={{ pl: 0.5 }} variant={'h5'}>JPEG, PNG</Typography>
        </RowBox>
        <RowBox>
          <Typography variant={'body1'} sx={{color: 'greys.dark'}}>Maximum size: </Typography>
          <Typography sx={{ pl: 0.5 }} variant={'h5'}>500KB</Typography>
        </RowBox>
      </RowBox>
    </>
  )
}

const RowBox = ({sx={}, children}) => {
  return <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      ...sx
    }}
  >
    {children}
  </Box>
}

export default ImageUpload