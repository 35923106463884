import { Box, SxProps, Theme, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import React from 'react'
import { styled } from '@mui/system'

export interface SimpleTooltipProps {
  title: string
  placement?: 'top-start' | 'top' | 'top-end' | 'bottom-start' | 'bottom' | 'bottom-end'
  children: React.ReactElement<any, any>
  open?: boolean
}

export const SimpleTooltip = ({
  title,
  placement,
  children,
  open,
}: SimpleTooltipProps) => {
  return (
    <Tooltip
    title={title}
    placement={placement}
    open={open}
    >
        {children}
    </Tooltip>
  )
}

export const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
})

export const IconImageTooltip = ({
  title,
  placement = 'bottom',
  popperSx = {},
  children,
  ...props
}: Omit<TooltipProps, 'children'> & {
  popperSx?: SxProps<Theme>,
  children: React.ReactNode
}) => {
  return (
    <Tooltip
      title={title}
      placement={placement}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 4],
              },
            },
          ],
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
              {
                marginBottom: 0,
              },
            [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
              {
                marginLeft: 0,
              },
            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
              {
                marginTop: 0,
              },
            [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
              {
                marginRight: 0,
              },
            [`&.${tooltipClasses.popper} .${tooltipClasses.tooltip}`]:
              {
                ...popperSx
              }
          }
        }
      }}
      {...props}
    >
      <Box sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', verticalAlign: 'inherit' }}>
        {children}
      </Box>
    </Tooltip>
  )
}

interface ExtendedTooltipProps extends TooltipProps {
  error?: boolean
}

export const StyledTooltip = styled(({ className, color, error, ...props }: ExtendedTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, color, error }) => ({
  [`& .${tooltipClasses.tooltipArrow}`]: {
    backgroundColor: color,
    boxShadow: (theme.shadows as string[])[5],
    color: theme.palette.greys.dark,
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      border: `1px solid ${color}`,
      color: theme.palette.common.white
    },
    color
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: error ? theme.palette.error.main : theme.palette.background.paper,
  },
}));