import React from 'react'
import MuiAppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import AccountCircle from '@mui/icons-material/AccountCircle'
import AddTask from '@mui/icons-material/AddTask'
import HelpOutline from '@mui/icons-material/HelpOutline'
import { AppBarMenu } from '../AppBarMenu'
import { Box, useTheme } from "@mui/material";
import { Typography } from "../Typography";
import CenterMenu from "../CenterMenu/CenterMenu";

interface AppBarProps {
  rightMenu?: React.ReactNode
  searchBar?: React.ReactNode
  logo?: string
  displayName?: string
  clinicName?: string
  menuOpen: boolean
  setMenuOpen: (boolean) => void
  routes: Array<any>
  createTask: () => void
  settingsRoute: React.ReactNode
}

export default function AppBar({
  searchBar,
  logo,
  displayName,
  clinicName,
  menuOpen,
  setMenuOpen,
  rightMenu,
  routes,
  createTask,
  settingsRoute
}: AppBarProps) {

  return (
    <MuiAppBar
      sx={{
        height: 56,
        boxShadow: 'none',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      position="fixed"
      color="primary"
    >
      <Toolbar
        sx={ theme => ({
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          padding: 0,
          '&.MuiToolbar-root': {
            p: theme.spacing(0, 1),
            minHeight: 56
          }
        })}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ display: 'inline-flex' }} data-testid={'saga-logo'}>
            {logo && <img alt={'Saga'} height={40} width={'auto'} src={logo} />}
          </Box>
          {!!searchBar && searchBar}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-40%)',
          }}
        >
          <CenterMenu routes={routes} />
        </Box>
        <Actions
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          displayName={displayName}
          clinicName={clinicName}
          createTask={createTask}
          settingsRoute={settingsRoute}
        >
          {rightMenu}
        </Actions>
      </Toolbar>
    </MuiAppBar>
  )
}

interface ActionsProps {
  children?: React.ReactNode
  displayName?: string
  clinicName?: string
  menuOpen: boolean
  setMenuOpen: (boolean) => void
  createTask: () => void
  settingsRoute: React.ReactNode
}

const Actions = ({
  children,
  displayName,
  clinicName,
  menuOpen,
  setMenuOpen,
  createTask,
  settingsRoute
}: ActionsProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const theme = useTheme()

  const openMenuHandler = (event) => {
    if (menuOpen) {
      handleClose()
    } else {
      setAnchorEl(event.currentTarget)
      setMenuOpen(true)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
    setMenuOpen && setMenuOpen(false)
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {children && (
        <>
          <IconButton
            data-testid={'open-create-task-modal-button'}
            color={'inherit'}
            onClick={createTask}
          >
            <AddTask />
          </IconButton>
          <IconButton
            data-testid={'saga-help-button'}
            color={'inherit'}
            onClick={() => {
              window.open('https://help.sagahealth.ca/portal/en/kb/saga-health', '_blank')
            }}
          >
            <HelpOutline />
          </IconButton>
          {settingsRoute}
          <IconButton
            data-testid={'saga-user-menu'}
            edge="end"
            aria-label="account of current user"
            color="inherit"
            onClick={openMenuHandler}
            sx={{
              maxWidth: '450px',
              mr: 0,
              borderRadius: 1,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
                borderRadius: 1,
              },
            }}
          >
            <AccountCircle
              sx={{ fontSize: 40 }}
              aria-controls="simple-menu"
              aria-haspopup="true"
            />
            <Box sx={{ pl: 1, textAlign: 'left' }}>
              <Typography
                lineclamp={1}
                sx={{
                  wordBreak: 'break-all',
                }}
              >
                {displayName}
              </Typography>
              <Typography
                variant={'p2'}
                lineclamp={1}
              >
                {clinicName}
              </Typography>
            </Box>
          </IconButton>

          <AppBarMenu
            open={menuOpen}
            anchorEl={anchorEl}
            handleClose={handleClose}
          >
            {children}
          </AppBarMenu>
        </>
      )}
    </Box>
  )
}