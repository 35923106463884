import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import { Box } from "@mui/material";
import { Typography } from "../Typography";
import React from "react";

interface LinearProgressWithLabelProps extends LinearProgressProps {
  value: number
  displayProgress: boolean
  sx?: any
  dataTestId?: string
}

const LinearProgressWithLabel = (props : LinearProgressWithLabelProps) => {
  const { displayProgress, sx, dataTestId, ...rest } = props

  return (
    <Box data-testid={dataTestId} sx={{ display: 'flex', alignItems: 'center', height: '16px', gap: 2, ...sx }}>
      {displayProgress && <LinearProgress data-testid={`${dataTestId}-progress-bar`} variant="determinate" {...rest} sx={{ width:  '100%', height: '8px', borderRadius: '8px'}} />}
      {displayProgress && <Typography data-testid={`${dataTestId}-progress-label`} variant="body2" sx={{ color: "text.secondary" }}>{`${Math.round(rest.value)}%`}</Typography>}
    </Box>
  )
}

export default LinearProgressWithLabel
