import React from 'react'
import { TypographyProps, Typography as MuiTypography, useTheme } from "@mui/material";
import { styled } from "@mui/system";

export type SagaTypographyVariants = "button" | "caption" | "h1" | "h2" | "h3" | "h4" | 'h5'| "subtitle1" | "body1" | "body2" | "overline" | "inherit" | "p2" | "p3" | "p4" | undefined

interface propsType extends TypographyProps {
  lineclamp?: number
  dataTestId?: string
}

export const Typography = (props: propsType) => {
  const theme = useTheme()
  const {dataTestId, ...rest } = props
  let color = 'inherit'
  if (props.variant) {
    color = theme.typography[props.variant]?.color || 'inherit'
  }
  let tempSx = props.sx;
  if (props.lineclamp) {
    tempSx = {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: props.lineclamp,
      WebkitBoxOrient: "vertical",
      whiteSpace: "normal",
      wordBreak: "break-word",
      ...tempSx
    }
  }
  let fontFamily = props.fontFamily
  if (props.fontFamily) {
    fontFamily = `${props.fontFamily} !important`
  }

  return <MuiTypography color={color} {...rest} sx={{ fontFamily: fontFamily, ...tempSx }} data-testid={dataTestId} />
}

export const TableHeaderTypography = styled(Typography)({
  fontSize: '12px !important'
})

export default Typography