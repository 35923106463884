import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { Typography } from "../Typography"
import Box from '@mui/material/Box'
import { SxProps, Theme, useTheme } from "@mui/material";

interface SpinnerProps {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  label?: string | null
  sx?: SxProps<Theme>
  thickness?: number,
  dataTestId?: string
}
export default function Spinner({ size = 'md', label, sx, thickness = 4, dataTestId }: SpinnerProps) {
  const theme = useTheme()
  const sizes = {
    xs: 14,
    sm: 25,
    md: 40,
    lg: 55,
    xl: 88
  }
  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <CircularProgress sx={{ ...sx }} size={sizes[size]} thickness={thickness} data-testid={`${dataTestId}-circularProgress`}/>
      {label && (
        <Typography variant="body1" sx={{ color: theme.palette.primary.main }} style={{marginTop: theme.spacing(2)}}>
          {label}
        </Typography>
      )}
    </Box>
  )
}
