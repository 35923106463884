import React, { ReactNode, useState } from "react";
import { AutocompleteBase as Autocomplete } from "saga-library/src/components/Autocomplete";
import { Controller, useFormContext } from "saga-library/src/components/Form";
import { AutocompleteRenderGetTagProps, Box, SxProps, Theme } from "@mui/material";
import { Chip } from "../Chip/Chip"
import { SimpleTextField } from "../TextField";
import { isValidEmail } from "saga-client/src/utils/validateEmail";

export interface ChipInputProps {
  name: string
  label?: string
  rules?: object
  chipVariant?: 'filled' | 'outlined'
  sx?: SxProps<Theme>,
  onInputChange?: (event: React.SyntheticEvent, value: string) => void
  onChange?: (event: React.SyntheticEvent, value: any) => void
  renderTags?: (value: any[], getTagProps: AutocompleteRenderGetTagProps) => ReactNode
  renderInput?: (value: any) => ReactNode
  dataTestId?: string
  options?: any[]
  freeSolo?: boolean
  multiple?: boolean
  disabled?: boolean
  disableClearable?: boolean
  popupIcon?: ReactNode
  noOptionsText?: string | ReactNode
  getOptionDisabled?: (option) => boolean
  autoSelect?: boolean
  tabAutoSelect?: boolean
}

export default function ChipInputField({
  name,
  label,
  rules,
  chipVariant = 'filled',
  sx,
  onInputChange,
  onChange,
  renderTags,
  renderInput,
  dataTestId,
  options = [],
  freeSolo = true,
  multiple = true,
  disabled = false,
  disableClearable = false,
  getOptionDisabled = () => false,
  popupIcon,
  noOptionsText,
  autoSelect = false,
  tabAutoSelect = true
}: ChipInputProps) {
  const { control } = useFormContext();
  return (
    <Controller
      defaultValue={[]}
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange: controlledOnChange, value , ref}, fieldState: {error} }) => {
        const onNewValue = (event, newValue, reason, message) => {
          controlledOnChange(newValue)
          if (onChange) {
            onChange(event, newValue);
          }
        }
        return (
          <Autocomplete
            data-testid={dataTestId}
            multiple={multiple}
            sx={sx}
            value={value}
            options={options}
            autoSelect={autoSelect}
            tabAutoSelect={tabAutoSelect}
            freeSolo={freeSolo}
            disableClearable={disableClearable}
            popupIcon={popupIcon}
            noOptionsText={noOptionsText}
            getOptionDisabled={getOptionDisabled}
            onInputChange={onInputChange}
            onChange={onNewValue}
            renderTags={renderTags ? renderTags : (value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  data-testid={`${dataTestId}-chip-${index}`}
                  {...getTagProps({ index })}
                  label={option}
                  size={'small'}
                  variant={chipVariant}
                />
              ))
            }
            renderInput={renderInput ? renderInput : ({ inputProps, InputLabelProps, InputProps, ...params }) => (
              <SimpleTextField
                {...params}
                name={name}
                value={value}
                dataTestId={`${dataTestId}-textfield`}
                inputRef={ref}
                variant="outlined"
                label={label}
                error={!!error}
                helperText={error?.message}
                multiline={true}
                disabled={disabled}
                slotProps={{
                  htmlInput: inputProps,
                  inputLabel: InputLabelProps,
                  input: InputProps
                }}
              />
            )}
            renderOption={(props, option, { index }) => (
              <Box component={"li"} {...props} key={option.name || option.label} data-testid={`${dataTestId}-${index}`}>
                {option.label}
              </Box>
            )}
          />
        )
      }}
    />
  )
}

export interface AutocompleteChipProps {
  label?: string
  sx?: SxProps<Theme>,
  getTagProps?: object
  onDelete?: () => void
  role?: string
  dataTestId?: string
}

export const AutocompleteChip = ({
  label,
  sx,
  getTagProps,
  onDelete,
  role = "chip",
  dataTestId
}: AutocompleteChipProps) => {
  return (
    <Chip
      {...getTagProps}
      data-testid={dataTestId}
      label={label}
      onDelete={onDelete}
      size={'small'}
      sx={{
        ...sx,
      }}
      role={role}
    />
  )
}

export const InviteChipInput = ({name}) => {
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true)
  const [onChangeError, setOnChangeError] = useState<boolean>(false)
  const [helperText, setHelperText] = useState<string>('')
  const delimiterCharacters = [' ', ';', ',']
  const { setError } = useFormContext()
  const invalidErrorMessage = 'Invalid email address'

  const onInputChange = (event, newInputValue) => {
    const endChar = newInputValue.slice(-1)
    if (delimiterCharacters.includes(endChar)) {
      const eventTarget = event.target as HTMLInputElement
      eventTarget.blur()
      eventTarget.focus()
    }
  }

  const onChange = (event, value: string[]) => {
    if (value.length > 0) { setShowPlaceholder(false)}
    else { setShowPlaceholder(true)}

    let validEmail = value.every((v) => isValidEmail(v))
    if (!validEmail) {
      setError(name, {
        message: invalidErrorMessage
      })
      setOnChangeError(true)
      setHelperText(invalidErrorMessage)
    } else {
      setOnChangeError(false)
      setHelperText('')
    }
  }

  return (
    <ChipInputField
      name={name}
      dataTestId={'inviteChipInput'}
      chipVariant={'outlined'}
      onChange={onChange}
      onInputChange={onInputChange}
      autoSelect={true}
      tabAutoSelect={true}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const validEmail = isValidEmail(option)
          return (
            <Chip
              {...getTagProps({ index })}
              data-testid={`inviteChipInput-chip-${index}`}
              label={option}
              size={'small'}
              variant={'outlined'}
              color={validEmail ? 'default' : 'error'}
            />
          )
        })
      }
      renderInput={({ inputProps, InputLabelProps, InputProps, ...params }) => (
        <SimpleTextField
          {...params}
          dataTestId={'inviteChipInput-textfield'}
          placeholder={showPlaceholder ? 'Enter email address(es), comma separated' : ''}
          error={onChangeError}
          helperText={helperText}
          slotProps={{
            htmlInput: inputProps,
            inputLabel: InputLabelProps,
            input: InputProps
          }}
        />
      )}
    />
  )
}
