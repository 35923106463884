import React, { useState } from "react";
import MuiTextField, {TextFieldProps as MuiTextFieldProps} from '@mui/material/TextField'
import { Controller, getFirstFieldErrorMessage, useFormContext } from "saga-library/src/components/Form";
import {
  useTheme,
  InputAdornment,
  SxProps,
  Theme,
  AutocompleteRenderInputParams,
  InputLabelProps,
  FormHelperTextProps,
  InputBaseComponentProps
} from '@mui/material'
import { MultipleValuesBatchMessage } from "saga-client/src/utils/ABClaimConstants"
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { disableAutocomplete } from "../../util/disableAutocomplete"
import { useSchemaRequired } from "../../hooks/useSchemaRequired";
import { IconButton } from "../Button";
import { InputProps } from "saga-library/src"

export interface TextFieldProps {
  dataTestId?: string
  name: string
  label: string
  sx?: SxProps<Theme>
  rules?: object
  type?: 'tel' | 'email' | 'text' | 'password' | 'number'
  multiline?: boolean
  rows?: number
  slotProps?: {
    htmlInput?: object
    input?: object
    inputLabel?: object
  }
  fullWidth?: boolean
  onMouseOver?: () => void
  onMouseLeave?: () => void
  onChange?: (e, v) => void
  autoFocus?: boolean
  disabled?: boolean
  extraEndAdornment?: React.ReactElement
  regexPattern?: RegExp,
  transformValue?: (value) => any
  inputRef?: any
  placeholder?: string
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  variant?: "standard" | "filled" | "outlined"
  hideHelperText?: boolean
  minRows?: number
}

export default function TextField({
  dataTestId,
  name,
  label,
  sx,
  rules,
  type = 'text',
  multiline,
  rows,
  slotProps,
  fullWidth,
  onMouseOver,
  onMouseLeave,
  autoFocus,
  disabled = false,
  extraEndAdornment,
  regexPattern,
  transformValue,
  inputRef,
  onChange: parentOnChange,
  placeholder,
  onFocus,
  onBlur,
  variant,
  hideHelperText = false,
  minRows
}: TextFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={''}
      render={({ field: { onChange, value: controlledValue = '', ref }, fieldState: {error} }) => {
        return <SimpleTextField
          name={name}
          helperText={!hideHelperText && getFirstFieldErrorMessage(error)}
          error={!!error}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={(e,)=>{
            if(regexPattern){
              if (e.target.value === "" || regexPattern.test(e.target.value)) {
                onChange(e.target.value);
                if(parentOnChange) {
                  parentOnChange(e, e.target.value)
                }
              }
            }else if(transformValue) {
              const transformedValue = transformValue(e.target.value)
              onChange(transformedValue)
              if (parentOnChange) {
                parentOnChange(e, transformedValue)
              }
            }else {
              onChange(e)
              if(parentOnChange) {
                parentOnChange(e, controlledValue)
              }
            }
          }}
          value={controlledValue || (controlledValue === 0 ? 0 : "")}
          label={label}
          type={type}
          multiline={multiline}
          rows={rows}
          slotProps={slotProps}
          fullWidth={fullWidth}
          inputRef={(e) => {
            ref(e)
            if(inputRef) {
              inputRef.current = e
            }
          }}
          sx={sx}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          autoFocus={autoFocus}
          disabled={disabled}
          extraEndAdornment={extraEndAdornment}
          dataTestId={dataTestId}
          placeholder={placeholder}
          variant={variant}
          minRows={minRows}
        />
      }}
    />
  )
}

export type SimpleTextFieldProps = MuiTextFieldProps & {
  warning?: boolean,
  extraEndAdornment?: React.ReactElement,
  hideHelperText?: boolean,
  dataTestId?: string,
  name?: string,
  value?: any
  slotProps?: object
}

export const SimpleTextField = ({
  dataTestId,
  autoComplete,
  warning = false,
  rows,
  multiline,
  sx,
  inputRef,
  extraEndAdornment,
  helperText,
  hideHelperText = false,
  name,
  value,
  minRows,
  slotProps,
  inputProps,
  InputProps,
  InputLabelProps,
  FormHelperTextProps,
  ...props
}: SimpleTextFieldProps) => {
  const theme = useTheme()
  const required = useSchemaRequired(name)

  if(minRows && rows){
    console.warn("The minRows props cannot be set on a component that also has rows prop set. The rows value will be respected.")
  }

  if (autoComplete === 'off') {
    autoComplete = disableAutocomplete()
  }

  const defaultStyles = {
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      m: 0
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      m: 0
    },
    '& input[type=number]': {
      MozAppearance: 'textfield'
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#fff',
    },
    mt: 1,
    gap: '4px',
    fontSize: '12px',
  }

  const htmlInputSlotProps = { ...inputProps, ...slotProps?.htmlInput } as InputBaseComponentProps
  const InputSlotProps = { ...InputProps, ...slotProps?.input } as InputProps
  const InputLabelSlotProps = { ...InputLabelProps, ...slotProps?.inputLabel } as InputLabelProps
  const FormHelperTextSlotProps = { ...FormHelperTextProps, ...slotProps?.formHelperText } as FormHelperTextProps

  return (
    <MuiTextField
      {...props}
      required={required && !value && value !== 0}
      value={value}
      data-testid={dataTestId}
      size={'small'}
      sx={[
        defaultStyles,
        ...(Array.isArray(sx) ? sx : [sx]),
        {'& .MuiOutlinedInput-notchedOutline': { borderColor: warning ? '#F68A1C !important' : '' }}
      ]}
      slotProps={{
        inputLabel: {
          "data-testid": `${dataTestId}-label`,
          sx: {
            fontSize: '14px',
            backgroundColor: '#fff',
            color: warning ? theme.palette.warning.main : '',
            '&.MuiInputLabel-shrink': {
              mx: '-4px',
              px: '6px'
            }
          },
          ...InputLabelSlotProps
        } as InputLabelProps,
        htmlInput: {
          "data-testid": `${dataTestId}-inputField`,
          ...htmlInputSlotProps
        },
        input: {
          "data-testid": `${dataTestId}-input`,
          style: {
            minHeight: InputSlotProps?.style?.minHeight ?? InputSlotProps?.style?.height ?? '40px',
            flexShrink: 0
          },
          ...InputSlotProps,
          endAdornment: (
            <>
              {InputSlotProps?.endAdornment}
              <InputAdornment
                position="end"
                data-testid= {`${dataTestId}-inputAdornment`}
              >
                {extraEndAdornment}
              </InputAdornment>
            </>
          )
        } as InputProps,
        formHelperText: {
          "data-testid": `${dataTestId}-helperText`,
          style: {
            lineHeight: '12px',
            minHeight: '12px',
            height: '100%',
            margin: '0px 16px'
          },
          ...FormHelperTextSlotProps
        } as FormHelperTextProps
      }}
      multiline={multiline}
      rows={rows}
      minRows={rows?undefined:minRows}
      autoComplete={autoComplete}
      inputRef={inputRef}
      helperText={!hideHelperText && (helperText ?? " ")}
    />
  )
}

export interface AutocompleteTextFieldProps {
  dataTestId?: string
  name?: string
  label?: string
  value?: string
  error?: boolean
  size?: 'small' | 'medium'
  params: AutocompleteRenderInputParams
  autoFocus?: boolean
  warning?: boolean
  inputRef?: React.Ref<any>
  disabled?: boolean
  placeholder?: string
  helperText?: string
}
export const AutocompleteTextField = ({
  dataTestId,
  label,
  name,
  value = '',
  error = false,
  size = 'small',
  params,
  autoFocus,
  warning = false,
  inputRef,
  disabled = false,
  placeholder,
  helperText,
}: AutocompleteTextFieldProps) => {
  const theme = useTheme()
  const required = useSchemaRequired(name)

  const {
    InputProps,
    inputProps,
    InputLabelProps,
    ...remainingParams
  } = params

  return (
    <MuiTextField
      {...remainingParams}
      data-testid={dataTestId}
      helperText={helperText}
      autoFocus={autoFocus}
      name={name}
      value={value}
      required={required && !value}
      label={label}
      error={error}
      size={size}
      inputRef={inputRef}
      disabled={disabled}
      placeholder={placeholder}
      slotProps={{
        formHelperText: {
          "data-testid": `${dataTestId}-helperText`
        } as FormHelperTextProps,
        inputLabel: {
          "data-testid": `${dataTestId}-label`,
          style: {
            fontSize: '14px',
            color: warning? theme.palette.warning.main : '',
          },
          ...InputLabelProps
        } as InputLabelProps,
        htmlInput: {
          "data-testid": `${dataTestId}-inputField`,
          ...inputProps
        },
        input: {
          "data-testid": `${dataTestId}-input`,
          ...InputProps
        } as InputProps
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: '8px',
        },
        '& .MuiOutlinedInput-notchedOutline': {borderColor: warning? '#F68A1C !important' : '' },
      }}
    />
  )
}

export interface InputMaskTextFieldProps {
  dataTestId?: string
  name: string
  label: string
  error?: boolean
  helperText?: string
  style?: object
  inputProps?: object
  type?: 'tel' | 'email' | 'text' | 'password'
  size?: 'small' | 'medium',
  value?: any
}

export const InputMaskTextField = ({
  dataTestId,
  name,
  label,
  error,
  helperText,
  style,
  inputProps,
  type,
  size = 'small',
  value
}: InputMaskTextFieldProps) => {
  const required = useSchemaRequired(name)

  return (
    <MuiTextField
      {...inputProps}
      autoComplete="off"
      sx={{
        width: '100%',
        margin: '8px 0',
        backgroundColor: '#fff',
        '& .MuiOutlinedInput-root': {
          height: '40px',
          borderRadius: '8px',
        },
        ...style }}
      slotProps={{
        inputLabel: {
          "data-testid": `${dataTestId}-label`,
          style: {
            fontSize: '14px',
          }
        } as InputLabelProps,
        htmlInput: {
          "data-testid": `${dataTestId}-inputField`
        },
        input: {
          "data-testid": `${dataTestId}-input`
        } as InputProps,
        formHelperText: {
          "data-testid": `${dataTestId}-helperText`,
        } as FormHelperTextProps
      }}
      data-testid={dataTestId}
      type={type}
      label={label}
      name={name}
      value={value}
      error={error}
      helperText={helperText}
      required={required && !value}
      size={size}
      variant="outlined"
    />
  )
}

export interface MultipleValuesTextFieldProps {
  dataTestId?: string
  label: string
  onKeyDown: (v) => void
  fullWidth?: boolean
  rows?: number
  multiline?: boolean
  onMouseOver?: () => void
  onMouseLeave?: () => void
  sx?: SxProps<Theme>
  inputRef?: React.Ref<HTMLInputElement>
  overflowActive?: boolean
  name?: string
}

export const MultipleValuesTextField = ({
  dataTestId,
  label,
  onKeyDown,
  fullWidth,
  rows,
  multiline,
  onMouseOver,
  onMouseLeave,
  sx,
  inputRef,
  overflowActive,
  name
}: MultipleValuesTextFieldProps) => {
  const theme = useTheme()
  return (
    <SimpleTextField
      name={name}
      dataTestId={dataTestId}
      label={label}
      value={overflowActive ? '[...]' : MultipleValuesBatchMessage}
      onKeyDown={onKeyDown}
      sx={{
        '& .MuiOutlinedInput-input': {
          color:  theme.palette.greys.light,
          fontStyle: 'italic',
          fontWeight: 400,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        width: '100%',
        ...sx
      }}
      fullWidth={fullWidth}
      rows={rows}
      multiline={multiline}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      inputRef={inputRef}
      disabled={true}
    />
  )
}

export interface PasswordFieldProps {
  dataTestId?: string
  name: string
  label: string
  sx?: SxProps<Theme>
  rules?: object
  multiline?: boolean
  rows?: number
  slotProps?: {
    htmlInput?: object
    input?: object
  }
  fullWidth?: boolean
  onMouseOver?: () => void
  onMouseLeave?: () => void
  autoFocus?: boolean
  autoComplete: 'password' | 'new-password'
}

export function PasswordField({
  dataTestId,
  name,
  label,
  sx,
  rules,
  slotProps,
  fullWidth,
  onMouseOver,
  onMouseLeave,
  autoFocus,
  autoComplete,
}: PasswordFieldProps) {
  const [showText, setShowText] = useState<boolean>(false);
  const { control } = useFormContext();

  const handleClickShowText = () => {
    setShowText(!showText)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value = '', ref }, fieldState: { error } }) => (
        <SimpleTextField
          name={name}
          dataTestId={dataTestId}
          helperText={error?.message}
          error={!!error}
          onChange={onChange}
          value={value || ''}
          label={label}
          type={showText ? 'text' : 'password'}
          autoComplete={autoComplete}
          slotProps={{
            ...slotProps,
            input: {
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowText}
                    onMouseDown={handleMouseDownPassword}
                    icon={showText ? <Visibility /> : <VisibilityOff />}
                  />
                </InputAdornment>,
              ...slotProps?.input
            }
          }}
          fullWidth={fullWidth}
          sx={sx}
          inputRef={ref}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          autoFocus={autoFocus}
        />
      )}
    />
  )
}