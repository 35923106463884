import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useFormContext } from "saga-library/src/components/Form"
import {
  DataGrid as MuiDataGrid,
  GridColDef,
  GridToolbarContainer,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridSlots,
  GridSlotProps,
  GridRow,
  GridRowId,
  GridRowModel,
  GridRenderEditCellParams,
  GridPreProcessEditCellProps,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridCellParams,
  GridEditInputCell,
  GridColumnMenu,
  GridColumnMenuProps,
  useGridApiRef,
  gridFilteredSortedRowEntriesSelector
} from '@mui/x-data-grid'
import { AddButton } from '../AddButton'
import { RemoveButton } from '../RemoveButton'
import {
  Box,
  Button as MuiButton,
  Checkbox as MuiCheckbox,
  IconButton as MuiIconButton,
  MenuItem,
  Select as MuiSelect,
  TextField as MuiTextField
} from '@mui/material'
import { GridDatePicker } from '../DatePicker'
import moment from 'moment-timezone'
import dayjs from 'dayjs'

export const borderlessFieldsSx = {
  width: '100%',
  mt: '-8px',
  '& .MuiInputBase-input': {
    fontSize: '12px'
  },
  '& .MuiOutlinedInput-root': {
    border: 'none',
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    }
  },
}

export type ExtendedGridColDef = GridColDef & {
  required?: boolean
};

export interface DataGridProps extends Omit<SimpleDataGridProps,  'initialRows' | 'onRowsChange'> {
  name: string
}

export const DataGrid = ({
  name,
  ...props
}: DataGridProps) => {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }}) => (
        <SimpleDataGrid
          {...props}
          initialRows={value || []}
          onRowsChange={(newRows) => onChange(newRows)}
          setValue={setValue}
        />
      )}
    />
  )
}


export const blurDataGrid = async (dataGridRef: React.MutableRefObject<any>) => {
  // Remove focus from DataGrid to process row update
  if (dataGridRef.current) {
    dataGridRef.current.blur()
  }
  // Small delay required for the row update to complete
  await new Promise(resolve => setTimeout(resolve, 500))
}

function convertToMoment(value: string | dayjs.Dayjs): moment.Moment {
  if (typeof value === 'string') {
    return moment(value)
  } else {
    return moment(value.toISOString()).utc()
  }
}

const validateDateInput = (params: GridPreProcessEditCellProps) => {
  const testDate = params.props.value
  if (testDate) {
    try {
      const newValue = convertToMoment(testDate)
      return { ...params.props, error: !newValue.isValid() };
    } catch (e) {
      return { ...params.props, error: true };
    }
  }
  return { ...params.props, error: false };
}

function wrapCellInError(node, error) {
  return (
    <Box
      sx={{
        width:'100%',
        border: (theme) => error ? `1px solid ${theme.palette.error.main}` : 'none',
      }}
    >
      {node}
    </Box>
  )
}

const cleanDataTestId = (dataTestId?: string) => {
  if (!dataTestId) {
    return ''
  }
  return dataTestId.replace('/', '').replace(' ', '')
}



const ColumnMenuWithDataTestIds = ({ dataTestId, ...props }: GridColumnMenuProps & { dataTestId: string }) => {
  const columnMenuRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    if (columnMenuRef?.current) {
      const menuItems = columnMenuRef.current.querySelectorAll('[role=menuitem]')
      menuItems.forEach((menuItem, index) => {
        menuItem.setAttribute('data-testid', `${dataTestId}-menuItem-${index}`)
      })
    }
  }, [columnMenuRef?.current])

  return <GridColumnMenu ref={columnMenuRef} {...props} />
}



interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

export interface SimpleDataGridProps {
  initialColumns: ExtendedGridColDef[]
  initialRows: any[]
  dataTestId: string
  addLabel?: string
  checkboxSelection?: boolean
  disabled?: boolean
  format?: string
  height?: string
  onRowsChange?: (newRows: any[]) => void
  emptyListMessage?: string
  dataGridRef?: React.MutableRefObject<any>
  setValue?: (name: string, value: any, options?: any) => void
}

export const SimpleDataGrid = ({
  initialColumns,
  initialRows,
  addLabel,
  dataTestId,
  checkboxSelection = false,
  disabled,
  onRowsChange,
  format = 'YYYY/MM/DD',
  emptyListMessage = 'There are no items in this list',
  height,
  dataGridRef,
  setValue
}: SimpleDataGridProps) => {
  const [rows, setRows] = useState(initialRows);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const [visibleColumnsCount, setVisibleColumnsCount] = useState<number>(0)
  const [hasFocus, setHasFocus] = useState(false)
  const apiRef = useGridApiRef()
  const addButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    setRows(initialRows)
  }, [initialRows])

  useEffect(() => {
    if (apiRef.current) {
      setVisibleColumnsCount(apiRef.current.getVisibleColumns().length)
    }
  }, [apiRef.current])

  useEffect(() => {
    if (apiRef.current?.rootElementRef?.current) {
      apiRef.current.rootElementRef.current.querySelectorAll('[role=columnheader]').forEach((header) => {
        if (!header.getAttribute('data-testid')) {
          header.setAttribute('data-testid', `${dataTestId}-columnHeader-${header.getAttribute('data-field')}`)
        }
      })
    }
  }, [visibleColumnsCount, dataTestId])

  useEffect(() => {
    if (setValue) {
      const isEditing = Object.values(rowModesModel).some((rowMode) => rowMode.mode === GridRowModes.Edit)
      setValue('isEditing', isEditing, { shouldDirty: false })
    }
  }, [rowModesModel, setValue])

  const setFocusAndCursor = useCallback((id, field) => {
    setTimeout(() => {
      if (dataGridRef?.current) {
        const editableCell = dataGridRef.current.querySelector(`[data-id="${id}"] [data-field="${field}"]`) as HTMLElement
        if (editableCell) {
          editableCell.focus()
          setTimeout(() => {
            const inputElement = editableCell.querySelector(field === 'Actions' ? 'button' : 'input')
            if (inputElement) {
              inputElement.focus()
            }
          }, 100)
        }
      }
    }, 100)
  }, [])

  const handleDeleteClick = useCallback((id: GridRowId) => () => {
    const allRows = Array.from(apiRef.current!.getRowModels()).map((row) => row[1])
    const updatedRows = allRows.filter((row) => row.id !== id)
    setRows(updatedRows)
    if (onRowsChange) {
      onRowsChange(updatedRows)
    }
    if (setValue) {
      const orderedRows = gridFilteredSortedRowEntriesSelector(apiRef)
      const isEditing = orderedRows.some((row) => rowModesModel[row.id]?.mode === GridRowModes.Edit)
      setValue('isEditing', isEditing, { shouldDirty: false })
    }
  }, [apiRef, onRowsChange, setRows, setValue])

  const columns: GridColDef[] = useMemo( () => {
    const tempColumns = initialColumns.map((column) => {
      const { required, hideable, ...rest } = column;
      let tempColumn: GridColDef = {
        ...rest,
        flex: 1,
        editable: disabled ? !disabled : rest.editable,
        preProcessEditCellProps: (params) => {
          if (required) {
            return { ...params.props, error: !params.props.value }
          }
          return params.props
        },
        hideable: hideable ?? !required
      }

      const renderEditCell = tempColumn.renderEditCell
      if (required && renderEditCell) {
        tempColumn = {
          ...tempColumn,
          renderEditCell: (params: GridRenderEditCellParams) => {
            const { error } = params;
            return wrapCellInError(renderEditCell(params), error)
          }
        }
      }

      if (rest.field === 'date') {
        const datePicker = (params) => (
          <GridDatePicker
            dataTestId={dataTestId}
            format={format}
            {...params}
          />
        )
        tempColumn = {
          ...tempColumn,
          preProcessEditCellProps: validateDateInput,
          renderCell: params => params?.value && convertToMoment(params?.value).isValid() ?
            convertToMoment(params?.value).format(format) : params?.value,
          renderEditCell: (props: GridRenderEditCellParams) => {
            const { error } = props
            if (error && setValue) {
              setValue('isEditing', true, { shouldDirty: false })
            }
            return wrapCellInError(datePicker(props), error)
          },
          flex: undefined,
          width: 220
        };
      }

      if (!tempColumn.renderEditCell) {
        tempColumn = {
          ...tempColumn,
          renderEditCell: (props: GridRenderEditCellParams) => (
            <GridEditInputCell {...props} data-testid={`${dataTestId}-${rest.field}`} />
          )
        }
      }

      return tempColumn
    })

    const actions: GridColDef = {
      field: 'Actions',
      type: 'actions',
      headerName: 'Actions',
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const rowIndex = apiRef.current?.getAllRowIds().indexOf(id)
        return [
          <RemoveButton
            tabIndex={-1} // all actions require this to be -1
            onClick={handleDeleteClick(id)}
            dataTestId={`${dataTestId}-row-${rowIndex}`}
            disabled={disabled}
          />
        ];
      }
    }

    if (addLabel) {
      return [...tempColumns, actions]
    }
    return tempColumns
  }, [initialColumns, addLabel])

  const changeRows = useCallback((currentRows, currentColumns, id, offset, nextColumnIndex, event) => {
    const currentRowIndex = currentRows.findIndex(row => row.id === id)
    const nextRowIndex = currentRowIndex + offset
    if (currentRowIndex !== -1 && (nextRowIndex >= 0 && nextRowIndex < currentRows.length)) {
      event.preventDefault()
      const nextRow = currentRows[nextRowIndex]
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.View },
        [nextRow.id]: { mode: GridRowModes.Edit },
      }))
      setFocusAndCursor(nextRow.id, currentColumns[nextColumnIndex].field)
    }
  }, [setRowModesModel])

  const handleColumnVisibilityModelChange = useCallback((_, details) => {
    setVisibleColumnsCount(details.api.getVisibleColumns().length)
  }, [])

  function CustomToolbar(props: EditToolbarProps) {
    if (addLabel && apiRef.current) {
      const { setRows, setRowModesModel } = props
      const visibleColumns = apiRef.current.getVisibleColumns()

      const handleAddClick = () => {
        const id = crypto.randomUUID()
        setRows((oldRows) => [{ id, isNew: true }, ...oldRows])
        setRowModesModel((oldModel) => ({
          [id]: { mode: GridRowModes.Edit, fieldToFocus: visibleColumns[0].field },
          ...oldModel,
        }))

        // Set the text cursor within the first editable cell
        setFocusAndCursor(id, visibleColumns[0].field)
      }

      return (
        <GridToolbarContainer>
          <AddButton
            ref={addButtonRef}
            label={`Add ${addLabel.toLowerCase()}`}
            dataTestId={dataTestId}
            onClick={handleAddClick}
            disabled={disabled}
          />
        </GridToolbarContainer>
      )
    }

    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
  }

  function BaseButton(props) {
    const name = typeof props.children === 'string' ? props.children : ''
    return <MuiButton {...props} data-testid={`${dataTestId}-${cleanDataTestId(name)}-button`} />
  }

  function BaseCheckbox(props) {
    return <MuiCheckbox {...props} data-testid={`${dataTestId}-checkbox-${cleanDataTestId(props.name)}`} />
  }

  const BaseIconButton = useMemo(() => React.forwardRef<HTMLButtonElement, any>((props, ref) => {
    return <MuiIconButton ref={ref} {...props} data-testid={`${dataTestId}-${cleanDataTestId(props['aria-label'])}-button`} />
  }), [dataTestId])

  function BaseSelect(props) {
    return <MuiSelect {...props} data-testid={`${dataTestId}-${cleanDataTestId(props.label)}-select`} />
  }

  function BaseSelectOption({ native, ...props }) {
    if (native) {
      return
    }
    return <MenuItem {...props} data-testid={`${dataTestId}-menuItem-${cleanDataTestId(props['data-value'])}`} />
  }

  function BaseTextField(props) {
    return <MuiTextField {...props} data-testid={`${dataTestId}-${cleanDataTestId(props.placeholder)}-textfield`} />
  }

  function Row(props) {
    return <GridRow {...props} data-testid={`${dataTestId}-row-${props.index}`} />
  }

  const handleRowModesModelChange = useCallback((newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }, [])

  const processRowUpdate = useCallback((newRow: GridRowModel) => {
    const updatedRow = { ...newRow }
    const allRows = Array.from(apiRef.current!.getRowModels()).map((row) => row[1])
    const updatedRows = allRows.map((row) => (row.id === newRow.id ? updatedRow : row))
    setRows(updatedRows)
    if (onRowsChange) {
      onRowsChange(updatedRows)
    }
    return updatedRow
  }, [onRowsChange, setRows, apiRef])

  const handleCellClick = useCallback((params: GridCellParams, event: React.MouseEvent) => {
    // Check if the delete button was clicked
    if ((event.target as HTMLElement).closest('.MuiDataGrid-actionsCell')) {
      return
    }

    // Check if the cell is already in edit mode
    if (params.isEditable && params.cellMode === 'edit') {
      return
    }

    setRowModesModel((oldModel) => {
      const newModel = { ...oldModel }
      Object.keys(newModel).forEach((id) => {
        newModel[id] = { mode: GridRowModes.View }
      })
      newModel[params.id] = { mode: GridRowModes.Edit }
      return newModel
    })
    setHasFocus(true)

    setFocusAndCursor(params.id, params.field)
  }, [])

  const handleCellKeyDown = useCallback((params: GridCellParams, event: React.KeyboardEvent) => {
    const visibleColumns = apiRef.current!.getVisibleColumns()
    const orderedRows = gridFilteredSortedRowEntriesSelector(apiRef)

    if (event.key === 'Tab') {
      let nextColumnIndex = apiRef.current!.getColumnIndex(params.field)

      if (event.shiftKey) {
        nextColumnIndex -= 1
        if (nextColumnIndex < 0) {
          nextColumnIndex = visibleColumns.length - 1
        }
        if (params.field === visibleColumns[0].field) {
          if (params.id === orderedRows[0].id) {
            // at the top and should return to regular functionality
            setRowModesModel((oldModel) => ({
              ...oldModel,
              [params.id]: { mode: GridRowModes.View },
            }))
            return
          } else {
            // make the row above edit mode and the current row view mode
            changeRows(orderedRows, visibleColumns, params.id, -1, nextColumnIndex, event)
            return
          }
        }
      } else {
        nextColumnIndex += 1
        if (nextColumnIndex >= visibleColumns.length) {
          nextColumnIndex = 0
        }
        if (params.field === visibleColumns[visibleColumns.length - 1].field) {
          if (params.id === orderedRows[orderedRows.length - 1].id) {
            // at the bottom and should return to regular functionality
            setRowModesModel((oldModel) => ({
              ...oldModel,
              [params.id]: { mode: GridRowModes.View },
            }))
            return
          } else {
            // make the row below edit mode and the current row view mode
            changeRows(orderedRows, visibleColumns, params.id, 1, nextColumnIndex, event)
            return
          }
        }
      }

      event.preventDefault()
      if (params.cellMode !== GridRowModes.Edit) {
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [params.id]: { mode: GridRowModes.Edit },
        }))
      }
      setFocusAndCursor(params.id, visibleColumns[nextColumnIndex].field)
    }
  }, [setRowModesModel, changeRows, apiRef])

  const handleFirstKeyDown = useCallback((event: KeyboardEvent) => {
    const sortedRows = gridFilteredSortedRowEntriesSelector(apiRef)
    const visibleColumns = apiRef.current!.getVisibleColumns()
    if (event.key === 'Tab' && sortedRows.length > 0) {
      if (!hasFocus) {
        event.preventDefault()
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [sortedRows[0].id]: { mode: GridRowModes.Edit },
        }))
        setFocusAndCursor(sortedRows[0].id, visibleColumns[0].field)
        setHasFocus(true)
      } else if (!event.shiftKey) {
        // if tabbed to the addMore button, then reset the focus on the first item in the datagrid
        const focusableButtons = Array.from(document.querySelectorAll<HTMLElement>(
          'button')).filter(el => !el.hasAttribute('disabled'))
        const currentIndex = focusableButtons.indexOf(document.activeElement as HTMLElement)
        let nextIndex = currentIndex + 1
        if (nextIndex >= focusableButtons.length) {
          nextIndex = 0
        }

        if (focusableButtons[nextIndex] === addButtonRef.current) {
          setHasFocus(false)
        }
      }
    }
  }, [hasFocus, setRowModesModel, setHasFocus])

  useEffect(() => {
    window.addEventListener('keydown', handleFirstKeyDown)

    return () => {
      window.removeEventListener('keydown', handleFirstKeyDown)
    }
  }, [handleFirstKeyDown])

  useEffect(() => {
    // handling initial focus on the blank first row
    const sortedRows = gridFilteredSortedRowEntriesSelector(apiRef)
    const visibleColumns = apiRef.current!.getVisibleColumns()
    if (addLabel && sortedRows.length === 1 && !hasFocus) {
      const { id, version, ...rest } = sortedRows[0].model
      if (Object.values(rest).every((value) => !value)) {
        setRows(rows => [{ ...rows[0], isNew: true }])
        setRowModesModel((oldModel) => ({
          [id]: { mode: GridRowModes.Edit, fieldToFocus: visibleColumns[0].field },
          ...oldModel,
        }))
        setFocusAndCursor(id, visibleColumns[0].field)
        setHasFocus(true)
      }
    }
  }, [hasFocus, addLabel, setRows, setRowModesModel])

  return (
    <Box sx={{ height: height || '100%' }}>
      <MuiDataGrid
        apiRef={apiRef}
        ref={dataGridRef}
        data-testid={`${dataTestId}-data-grid`}
        rows={rows}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        onCellClick={handleCellClick}
        onCellKeyDown={handleCellKeyDown}
        editMode={'row'}
        columns={columns}
        checkboxSelection={checkboxSelection}
        disableRowSelectionOnClick={true}
        localeText={{ noRowsLabel: emptyListMessage }}
        getRowHeight={() => 40}
        disableColumnResize={!!addLabel}
        slots={{
          toolbar: CustomToolbar as unknown as GridSlots['toolbar'],
          baseButton: BaseButton,
          baseCheckbox: BaseCheckbox,
          baseIconButton: BaseIconButton,
          baseSelect: BaseSelect,
          baseSelectOption: BaseSelectOption as GridSlots['baseSelectOption'],
          baseTextField: BaseTextField,
          columnMenu: (props) => <ColumnMenuWithDataTestIds {...props} dataTestId={`${dataTestId}-columnsPanel`} />,
          row: Row
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel } as GridSlotProps['toolbar'],
          pagination: {
            slotProps: {
              actions: {
                //@ts-ignore
                previousButton: { 'data-testid': `${dataTestId}-previous-button` },
                //@ts-ignore
                nextButton: { 'data-testid': `${dataTestId}-next-button` }
              }
            },
          },
          //@ts-ignore
          columnsPanel: { 'data-testid': `${dataTestId}-columnsPanel` },
          filterPanel: {
            //@ts-ignore
            'data-testid': `${dataTestId}-filterPanel`,
            filterFormProps: {
              columnInputProps: { 'data-testid': `${dataTestId}-filterPanel-column-input` },
              logicOperatorInputProps: { 'data-testid': `${dataTestId}-filterPanel-logicOperator-input` },
              operatorInputProps: { 'data-testid': `${dataTestId}-filterPanel-operator-input` },
              valueInputProps: { 'data-testid': `${dataTestId}-filterPanel-value-input` }
            }
          }
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: addLabel ? 10 : 18,
            },
          },
        }}
        pageSizeOptions={addLabel ? [10] : [18]}
        sx={{
          border: 'none !important',
          '& .MuiDataGrid-root .MuiDataGrid-main': {
            border: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'backgrounds.hover',
          },
          '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle': {
            color: 'greys.medium',
          },
          '& .MuiDataGrid-cell--editable:hover': {
            cursor: 'pointer',
          },
        }}
      />
    </Box>
  )
}

export default DataGrid